.ant-descriptions-item-label {
  width: 0;
  white-space: nowrap;
}

.ant-ribbon {
  z-index: 1;
}

.ant-tree {
  padding-top: 0.25rem;
}
html,
body {
  background-color: #121212;
  font-family: "Titillium Web", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  padding: 0;
  margin: 0;
  letter-spacing: 0;
  scroll-behavior: smooth;
}

* {
  box-sizing: border-box;
}

ul,
li {
  list-style: none;
}

a {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  line-height: 1.2;
}

h1,
h2,
h3,
h4 {
  font-weight: 700 !important;
}

b {
  font-weight: 900;
}

input {
  outline: none;
}
#root {
  max-width: 100dvw;
  min-height: 100dvh;
  display: flex;
  font-family: "Titillium Web", serif;
}
#root > .ant-app {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
}
#root > .ant-app > .ant-spin {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

body:after {
  flex: 0;
  content: "";
  display: block;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 0.5rem;
  z-index: 1;
  background-color: #121212;
  background-image: linear-gradient(225deg, #d62973 0%, #2973d6 100%);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

html.no-scroll body {
  overflow: hidden;
}

/*
html.html-mfa-view,
html.html-auth-view {
    body {
        &:before {
            flex: 0;
            content: "";
            display: block;
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            height: 0.5rem;
            z-index: 1;
            background-color: $bg_color;
            background-image: $gradient_main;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
        }
    }
}
*/
a[href] {
  color: #a0b7ec;
}

.ant-btn.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  color: #c2c2c2;
}

.ant-tag-clickable {
  cursor: pointer;
}
.ant-ribbon-text a {
  color: #fff;
}
.chart-wrapper {
  height: 400px;
}

.ReactChart {
  font-family: "Titillium Web", serif !important;
  background-image: radial-gradient(at center, rgba(0, 0, 0, 0.5), transparent 75%, transparent);
}
.ReactChart .grid {
  background-color: #000;
}

.apexcharts-xaxis-label {
  fill: rgba(255, 255, 255, 0.5);
}

.apexcharts-yaxis-label {
  fill: rgba(255, 255, 255, 0.5);
}

.mapboxgl-popup-content {
  color: #121212;
}
.dashboard-general-stats {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: stretch;
  gap: 0.5rem;
  border-radius: 1rem;
  z-index: 1;
  flex-wrap: wrap;
  padding: 0 0.5rem;
}
.dashboard-general-stats > div {
  flex: 1;
}
@media screen and (min-width: 1024px) {
  .dashboard-general-stats {
    padding: 0;
  }
}

.dashboard-general-stats-item {
  position: relative;
  text-align: center;
  flex: 1;
  min-height: 120px;
  min-width: 150px;
  padding: 1rem;
  border-radius: 1rem;
  background-color: rgba(25, 25, 25, 0.8);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  overflow: hidden;
}
.dashboard-general-stats-item h4 {
  font-size: 2rem;
  font-weight: 900;
  line-height: 2rem;
}
.dashboard-general-stats-item p {
  font-size: 0.75rem;
  line-height: 0.75rem;
  text-transform: uppercase;
  font-weight: 300;
}
@media screen and (min-width: 800px) {
  .dashboard-general-stats-item {
    min-width: 230px;
  }
  .dashboard-general-stats-item h4 {
    font-size: 3rem;
    font-weight: 900;
    line-height: 3rem;
  }
  .dashboard-general-stats-item p {
    font-size: 0.875rem;
    line-height: 0.875rem;
    text-transform: uppercase;
    font-weight: 300;
  }
}

.dashboard-general-stats-item .stats-card-front,
.dashboard-general-stats-item .stats-card-back {
  position: absolute;
  inset: 0;
  min-height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.75rem;
  transition: transform 1s cubic-bezier(0.16, 1, 0.3, 1), opacity 1s cubic-bezier(0.16, 1, 0.3, 1), filter 1s cubic-bezier(0.16, 1, 0.3, 1);
}

.dashboard-general-stats-item .stats-card-front {
  transform: translateY(0);
  opacity: 1;
  filter: blur(0);
}

.dashboard-general-stats-item .stats-card-back {
  transform: translateY(100%);
  opacity: 0;
  filter: blur(6px);
}

.dashboard-general-stats-item.flippable:hover .stats-card-front {
  transform: translateY(-100%);
  opacity: 0;
  filter: blur(6px);
}
.dashboard-general-stats-item.flippable:hover .stats-card-back {
  transform: translateY(0);
  opacity: 1;
  filter: blur(0);
}

.dashboard-general-stats-color {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}
.dns-timeline {
  width: 20rem;
}
.dns-timeline .ant-timeline-item-content {
  white-space: nowrap;
  width: auto;
  padding-top: 0.25rem;
}
.dns-timeline .ant-timeline-item-content p {
  width: clamp(20rem, 50%, 100%);
  white-space: wrap;
}
.dns-timeline pre {
  padding: 0;
  margin: 0;
  font-family: "Titillium Web", serif;
  white-space: wrap;
}
.dns-timeline .ant-timeline-item-label {
  padding-right: 0.5rem;
}
.dns-timeline .ant-timeline {
  margin-top: 0;
  padding-top: 0.2rem;
}
.dns-timeline .ant-timeline .ant-timeline-item-tail {
  inset-inline-start: 0;
}
.dns-timeline .ant-timeline .ant-timeline-item-head {
  inset-inline-start: 0;
}
.dns-timeline .ant-timeline .ant-timeline-item-content {
  inset-inline-start: 0.5rem !important;
  width: auto !important;
}
.dropzone {
  position: relative;
  cursor: pointer;
  font-size: 0;
  overflow: hidden;
}
.dropzone input {
  transform: translate(-20%, -10px);
  opacity: 0;
  position: absolute;
  cursor: pointer;
  width: 100px;
  height: 100px;
}
.dropzone input::-webkit-file-upload-button {
  /* chromes and blink button */
  cursor: pointer;
}
.dropzone p {
  cursor: pointer;
  text-align: center;
  width: 60%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.dropzone.drag-active {
  background-color: #ccc;
}
.footer {
  margin-top: auto;
  text-align: center;
  color: #fff;
  padding-bottom: 2rem;
}
.gradient-section {
  position: relative;
  margin: 0.5rem;
  padding: 1rem;
}
.gradient-section.double {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.gradient-section.double .small-report {
  flex: 1;
}
@media screen and (min-width: 1440px) {
  .gradient-section.double {
    flex-direction: row;
  }
  .gradient-section.double .small-report {
    width: 50%;
    min-width: 0;
  }
}
.gradient-section:before {
  content: "";
  display: block;
  position: absolute;
  inset: -0.5rem;
  opacity: 0.75;
  background-image: linear-gradient(225deg, #d62973 0%, #2973d6 100%);
  z-index: -1;
  border-radius: 1rem;
}
.gradient-section::after {
  content: "";
  display: block;
  position: absolute;
  inset: 0;
  background-color: #121212;
  z-index: -1;
  border-radius: 1rem;
}
#header-container {
  background-color: #121212;
  background-image: linear-gradient(225deg, #d62973 0%, #2973d6 100%);
}
@media screen and (min-width: 1024px) {
  #header-container {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

#header-portal {
  padding: 0.5rem;
  margin-left: 0;
  margin-right: 0;
  max-width: 1440px;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  z-index: 1;
  background-color: rgba(18, 18, 18, 0.9);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
}
#header-portal h1 {
  text-align: center;
}
#header-portal .toolbar {
  margin-bottom: 0;
}
#header-portal .dashboard-general-stats-item {
  background-color: rgba(32, 32, 32, 0.8);
}
@media screen and (min-width: 800px) {
  #header-portal {
    margin-left: 2rem;
    margin-right: 2rem;
    padding: 2rem;
    padding-bottom: 1rem;
  }
  #header-portal h1 {
    text-align: left;
  }
}
@media screen and (min-width: 1024px) {
  #header-portal {
    margin-left: 1.45rem;
    margin-right: 1.45rem;
  }
}
@media screen and (min-width: 1440px) {
  #header-portal {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
}

#header {
  padding-top: 2rem;
  margin-bottom: 2rem;
}
@media screen and (min-width: 800px) {
  #header {
    padding: 2rem;
    padding-bottom: 0;
    padding-top: 2.5rem;
    display: flex;
    align-items: center;
  }
}
#header .header-more {
  position: absolute;
  right: 1rem;
  top: 2rem;
  z-index: 1;
}
#header .header-more > a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  color: #f5f5f5;
}
#header .header-more > a svg {
  width: 2rem;
  height: 2rem;
}
@media screen and (min-width: 800px) {
  #header .header-more {
    display: none;
  }
}
#header .header-menus {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  transform: translate(-100%);
  width: 100%;
  transition: transform 0.5s cubic-bezier(0.16, 1, 0.3, 1);
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.95);
  bottom: 0;
  top: 7rem;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}
#header .header-menus.active {
  transform: translate(0);
}
@media screen and (min-width: 800px) {
  #header .header-menus {
    position: static;
    transform: none;
    width: auto;
    background-color: transparent;
    z-index: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  #header .header-menus .ant-menu {
    border-bottom: 0;
  }
}
#header .header-home-link {
  margin-left: auto;
  margin-right: auto;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  color: #fff;
}
#header .header-home-link h1 {
  font-size: 3rem;
  line-height: 0;
  transform: translateY(-0.3rem);
  display: inline;
  letter-spacing: -0.025em;
  position: relative;
}
#header .header-home-link h1 span {
  font-size: 1rem;
  display: block;
  position: absolute;
  bottom: -1.7rem;
  right: 0;
  font-weight: 300;
  letter-spacing: -0.01em;
}
@media screen and (min-width: 800px) {
  #header .header-home-link {
    margin-left: 0;
    margin-right: 2rem;
  }
}
#header .icon {
  width: 12rem;
  color: #fff;
}
#header .ant-menu {
  background-color: transparent;
}
#header .header-nav-menu {
  flex: 1;
}
#header .header-user-menu {
  flex: 1;
  justify-content: flex-end;
}
#header .header-top-badge sup {
  z-index: 1;
  box-shadow: none;
  background-color: rgba(255, 255, 255, 0.7);
  color: #444;
  position: static;
  vertical-align: baseline;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
#header .header-menus > .ant-menu > .ant-menu-item:hover:after {
  border-color: rgba(255, 255, 255, 0.5);
}
#header .header-menus > .ant-menu > .ant-menu-item.ant-menu-item-active:after {
  border-color: rgba(255, 255, 255, 0.5);
}
#header .header-menus > .ant-menu > .ant-menu-item.ant-menu-item-selected {
  background-color: rgba(255, 255, 255, 0.25);
  border-radius: 0.5rem;
}
#header .header-menus > .ant-menu > .ant-menu-item.ant-menu-item-selected:after {
  display: none;
}
#header .header-menus > .ant-menu > .ant-menu-item.ant-menu-item-selected:hover {
  background-color: rgba(255, 255, 255, 0.25);
}
#header .header-menus > .ant-menu > .ant-menu-item.ant-menu-item-selected a,
#header .header-menus > .ant-menu > .ant-menu-item.ant-menu-item-selected .ant-menu-item-icon,
#header .header-menus > .ant-menu > .ant-menu-item.ant-menu-item-selected .ant-menu-title-content {
  color: #fff;
}
#header .header-menus > .ant-menu > .ant-menu-submenu:hover:after {
  border-color: rgba(255, 255, 255, 0.5);
}
#header .header-menus > .ant-menu > .ant-menu-submenu.ant-menu-submenu-active:after {
  border-color: rgba(255, 255, 255, 0.5);
}
#header .header-menus > .ant-menu > .ant-menu-submenu.ant-menu-submenu-selected {
  background-color: rgba(255, 255, 255, 0.25);
  border-radius: 0.5rem;
}
#header .header-menus > .ant-menu > .ant-menu-submenu.ant-menu-submenu-selected:after {
  display: none;
}
#header .header-menus > .ant-menu > .ant-menu-submenu.ant-menu-submenu-selected:hover {
  background-color: rgba(255, 255, 255, 0.25);
}
#header .header-menus > .ant-menu > .ant-menu-submenu.ant-menu-submenu-selected a,
#header .header-menus > .ant-menu > .ant-menu-submenu.ant-menu-submenu-selected .ant-menu-title-content,
#header .header-menus > .ant-menu > .ant-menu-submenu.ant-menu-submenu-selected .ant-menu-item-icon {
  color: #fff;
}

.header-sub-badge sup {
  z-index: 1;
  box-shadow: none !important;
  background-color: rgba(255, 255, 255, 0.7) !important;
  color: #222 !important;
  position: static !important;
  margin-left: 0.5rem;
  vertical-align: baseline;
}

.header-single-row h1 {
  margin-bottom: 0;
  padding-bottom: 0.5rem;
}
.icon-link a {
  margin-left: 0.5rem;
}
.form-input-container .ant-typography-danger {
  padding-left: 0.4rem;
  margin-bottom: 0;
}
.json-data-editor {
  padding: 10px;
  padding-left: 0;
}
.json-response {
  min-height: 200px;
}
.map {
  max-width: 100%;
  width: 100%;
  height: 400px;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.mapboxgl-popup-content a {
  color: #3666d6;
}

.mapboxgl-ctrl-logo {
  display: none !important;
}

.mapboxgl-ctrl-attrib {
  display: none !important;
}
.ant-menu-submenu .ant-menu-item-selected {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
.ant-menu-submenu .ant-menu-item-selected .ant-menu-title-content {
  color: #fff;
}
.ant-menu-submenu .ant-badge-count {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-selected {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-selected .ant-dropdown-menu-title-content {
  color: #fff;
}
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-selected .ant-dropdown-menu-item-icon {
  color: #fff;
}
.ant-modal:before {
  content: "";
  display: block;
  position: absolute;
  inset: -0.25rem;
  opacity: 0.85;
  background-image: linear-gradient(225deg, #d62973 0%, #2973d6 100%);
  z-index: -1;
  border-radius: 0.5rem;
}
.ant-modal::after {
  content: "";
  display: block;
  position: absolute;
  inset: 0;
  background-color: #121212;
  z-index: -1;
  border-radius: 1rem;
}

.ant-modal:has(.ant-modal-content > .ant-modal-footer) {
  padding-bottom: 0;
}
.notifications-dialog {
  max-width: 20rem;
}
.notifications-dialog .ant-popover-inner {
  margin-top: 1rem;
  border: 4px solid rgba(255, 255, 255, 0.5);
  max-height: 40rem;
  overflow: auto;
}

.notification-item {
  position: relative;
}
.notification-item::after {
  content: "";
  display: block;
  height: 1px;
  width: 75%;
  transform: translate(-50%, 0.75rem);
  left: 50%;
  bottom: 0;
  position: absolute;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.1);
}

.notification-info {
  flex: 1;
}
.notification-info p:first-child {
  font-weight: 300;
  text-transform: uppercase;
}
.notification-info p:first-child a {
  color: #fff;
}
.notification-info p:first-child a:hover {
  text-decoration: underline;
}
.notification-info p:nth-child(2) {
  font-size: 0.65rem;
  font-weight: 700;
}
.notification-info p:last-child {
  font-size: 0.75rem;
  color: rgba(255, 255, 255, 0.5);
}

.notification-state a .anticon {
  color: rgba(255, 255, 255, 0.5);
  transition: color 0.3s;
}
.notification-state a:hover .anticon {
  color: rgb(255, 255, 255);
}

.notifications-dialog-clear-all {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  justify-content: flex-end;
}
.notifications-disabled {
  position: relative;
}
.notifications-disabled::after {
  pointer-events: none;
  content: "";
  display: block;
  position: absolute;
  width: 1.1rem;
  height: 1.1rem;
  top: 100%;
  left: 25%;
  border-left: 1px solid #fff;
  transform: rotate(45deg) translate(-50%, -50%);
  transform-origin: center;
}
.page {
  padding: 0.5rem;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 2rem;
  max-width: 1440px;
  border-bottom: 1px solid #171717;
  z-index: 1;
  background-color: rgba(18, 18, 18, 0.9);
}
.page h1 {
  text-align: center;
}
.page.transparent {
  background-color: transparent;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  box-shadow: none;
}
@media screen and (min-width: 800px) {
  .page {
    margin-left: 1.45rem;
    margin-right: 1.45rem;
    padding: 0.5rem;
    padding-top: 1rem;
  }
  .page h1 {
    text-align: left;
  }
}
@media screen and (min-width: 1440px) {
  .page {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
}

.page-general-stats {
  max-width: 1440px;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: stretch;
  gap: 0.5rem;
  border-radius: 1rem;
  z-index: 1;
  padding: 0;
}
.page-general-stats > div {
  flex: 1;
}
.page-general-stats .dashboard-general-stats-item {
  min-height: 100px;
  min-width: 0;
  flex: 1;
}
.page-general-stats .dashboard-general-stats-item .stats-card-front,
.page-general-stats .dashboard-general-stats-item .stats-card-back {
  min-height: 100px;
}
.page-general-stats h4 {
  font-size: 1.5rem;
  line-height: 1.5rem;
}
.page-general-stats p {
  font-size: 0.75rem;
  line-height: 0.75rem;
}
@media screen and (min-width: 800px) {
  .page-general-stats h4 {
    font-size: 2.25rem;
    line-height: 2.25rem;
  }
  .page-general-stats p {
    font-size: 0.875rem;
    line-height: 0.875rem;
  }
}
@media screen and (min-width: 1440px) {
  .page-general-stats {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    padding: 0;
  }
}

.bg-page {
  background-color: #121212;
}

.section-top-border {
  position: relative;
}
.section-top-border::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: -0.5rem;
  right: 0;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.15);
  background-image: linear-gradient(225deg, #d62973 0%, #2973d6 100%);
  opacity: 0.25;
}
.random-data {
  /*.ant-table-expanded-row {
      .ant-table-cell {
          padding: 0 !important;
          .ant-descriptions-view {
              border: 0;
          }
      }
  }*/
}
.random-data th {
  white-space: nowrap;
}
.random-data .ant-descriptions-item-content:has(.ant-descriptions) {
  padding: 0 !important;
}
.random-data .ant-descriptions-item-content:has(.ant-table) {
  padding: 0 !important;
}
.random-data .ant-descriptions-view .ant-descriptions-view {
  border: 0;
}
.random-data .ant-descriptions-view .ant-table-container {
  border: 0 !important;
}
.random-data .ant-table-expanded-row .ant-table-container {
  border: 1px solid rgba(253, 253, 253, 0.12) !important;
}
.random-data .ant-descriptions + .ant-typography {
  margin-top: 0.5em;
}
.random-data .ant-table-wrapper + .ant-typography {
  margin-top: 0.5em;
}
.random-data .ant-descriptions-item-content .ant-list-item {
  padding: 2px;
  margin-block-end: 0;
}
.random-data .ant-descriptions-item-content .ant-descriptions + .ant-typography,
.random-data .ant-descriptions-item-content .ant-table-wrapper + .ant-typography {
  border-top: 1px solid rgba(253, 253, 253, 0.12);
  padding-top: 0.5em;
  padding-left: 0.5em;
  margin-top: 0;
}

.random-data-long-text {
  max-width: 50%;
}

.random-data-pop {
  max-width: 80%;
  max-height: 50%;
}
.random-data-pop .ant-popover-inner-content {
  overflow: scroll;
}
.random-data-pop .ant-descriptions-item-content span {
  word-break: keep-all !important;
}
.random-data-pop .ant-descriptions-item-content:has(.ant-descriptions) {
  padding: 0 !important;
}
.random-data-pop .ant-descriptions-item-content:has(.ant-table) {
  padding: 0 !important;
}
.random-data-pop .ant-descriptions-view .ant-descriptions-view {
  border: 0;
}

.ant-table .random-data .ant-table-container {
  border-top: 1px solid #303030 !important;
}
.ant-table .random-data .ant-table {
  margin-inline-start: 0 !important;
}
.scrollable {
  overflow-y: auto;
}
.ant-select {
  min-width: 150px;
}
.selector-dialog-body {
  max-height: 25rem;
  overflow: auto;
}
.ant-spin {
  max-height: none !important;
}
.table-full-width.ant-table-wrapper table {
  width: 100% !important;
}
.ant-descriptions-view .table-full-width.ant-table-wrapper table {
  width: 100%;
}

.table-cell-collapse {
  width: 0;
}

.table-cell-max-content {
  min-width: max-content;
}

.table-cell-nowrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table-header-nowrap th {
  white-space: nowrap;
}

.ant-table-expanded-row .ant-table-cell {
  background-color: #0d0d0d !important;
}

.table-transparent-header th {
  background-color: transparent !important;
}
.table-transparent-header th::before {
  display: none;
}
.toolbar {
  margin-bottom: 0.5rem;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  min-height: 2rem;
}
.toolbar > a {
  color: #f5f5f5;
}
.toolbar h1,
.toolbar h2,
.toolbar h3,
.toolbar h4,
.toolbar h5 {
  margin-bottom: 0;
}

.toolbar-right {
  margin-left: auto;
}

.page-title-toolbar {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}
.page-title-toolbar h1,
.page-title-toolbar h2,
.page-title-toolbar h3 {
  margin-right: auto;
  margin-bottom: 0;
}
.blacklist-tooltip {
  max-width: 30rem;
}
.blacklist-tooltip .ant-tooltip-inner p {
  margin-bottom: 0.5rem;
}
.blacklist-tooltip .ant-tooltip-inner p:last-child {
  margin-bottom: 0;
}
.watchlists-table h4 {
  margin-bottom: 0;
  text-align: center;
}
.watchlists-table .ant-table-expanded-row .ant-table {
  margin-block: 0 !important;
  margin-inline: 0 !important;
}
.watchlists-table .ant-table-expanded-row .ant-table-cell .ant-table-container {
  border-top: 1px solid #303030;
}
.watchlists-table .anticon-minus-circle {
  color: green;
}
.watchlists-table .anticon-exclamation-circle {
  color: orange;
}
.watchlists-table .anticon-exclamation-circle.active {
  color: red;
}
.watchlists-table .watchlist-cell-wrap {
  word-break: break-all;
}
.page-companies .ant-descriptions .ant-table-container {
  border-top: 1px solid #303030 !important;
}
.page-companies .ant-descriptions .ant-table {
  margin-inline: 0 0 !important;
  margin-block: 0 !important;
}
.page-companies .ant-descriptions .ant-descriptions-item-content {
  padding: 0 !important;
}
.page-companies .ant-descriptions .ant-descriptions-item-label {
  text-align: center;
}
.page-companies .ip-tag {
  display: inline-flex !important;
}
.page-companies .ip-tag .anticon {
  color: #999;
  width: 0.75rem;
  height: 0.75rem;
}
.page-companies .watchlists-table table {
  width: 100% !important;
}

.company-form-dropzone {
  min-height: 3rem;
  border-width: 1px;
  border-style: dotted;
  border-color: #444;
  border-radius: 0.5rem;
}
.company-form-dropzone p {
  font-size: 0.875rem;
}

.company-form-logo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 1rem;
}
.company-form-logo img {
  max-height: 100px;
}
.company-form-logo .company-form-logo-image {
  flex: 1;
}
.company-form-logo .company-form-logo-actions {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.page-company {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.page-company h1 {
  margin-bottom: 0;
}
.page-company .page-dashboard-reports-row {
  box-shadow: none;
  padding: 0;
}

.page-company-logo img {
  max-height: 100px;
}

.page-company-domains {
  line-height: 1;
}
.page-company-domains a {
  font-size: 1.5rem;
}

.page-company-map {
  position: relative;
}
.page-company-map h3 {
  position: absolute;
  z-index: 1;
}

html.page-company #header-portal {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.page-company-cidrs {
  column-gap: "1rem";
  column-count: 5;
}
.page-country {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

html.page-country #header-portal {
  padding: 1rem;
  box-shadow: none;
}
.page.page-dashboard {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0;
  margin-left: 0;
  margin-right: 0;
}
.page.page-dashboard .report-top-victims .ant-select {
  display: none;
}
.page.page-dashboard .ant-spin-container::after {
  background: none;
}
@media screen and (min-width: 800px) {
  .page.page-dashboard {
    margin-left: 1.45rem;
    margin-right: 1.45rem;
  }
  .page.page-dashboard .dashboard-general-stats {
    flex-wrap: wrap;
  }
  .page.page-dashboard .report-top-victims .ant-select {
    display: block;
  }
}
@media screen and (min-width: 1024px) {
  .page.page-dashboard .dashboard-general-stats {
    flex-wrap: wrap;
  }
}
@media screen and (min-width: 1440px) {
  .page.page-dashboard {
    margin-left: auto;
    margin-right: auto;
  }
}

html.page-dashboard #header-portal {
  position: relative;
  padding: 0;
}
html.page-dashboard #header-portal h3 {
  position: absolute;
  left: 1rem;
  top: 1rem;
  z-index: 1;
}
html.page-dashboard #header-portal .ant-spin-blur::after {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}
html.page-dashboard #header-portal .mapboxgl-map {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}
html.page-dashboard #header-portal .dashboard-general-stats {
  flex-wrap: wrap;
  position: absolute;
}
html.page-dashboard #header-portal .dashboard-general-stats .ant-spin-blur::after {
  border-radius: 1rem;
}

.page-dashboard-reports-row {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 1rem;
  border-radius: 1rem;
  z-index: 1;
  background-color: rgba(18, 18, 18, 0.9);
}
.page-dashboard-reports-row .small-report {
  flex: 1;
  min-width: 0;
}
.page-dashboard-reports-row .chart-wrapper {
  height: 400px;
}
.page-dashboard-reports-row.w-divider {
  position: relative;
}
.page-dashboard-reports-row.w-divider::before {
  content: "";
  display: block;
  position: absolute;
  left: 0%;
  top: 50%;
  right: 0%;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.15);
  background-image: linear-gradient(225deg, #d62973 0%, #2973d6 100%);
  opacity: 0.25;
}
.page-dashboard-reports-row.single {
  gap: 0;
}
@media screen and (min-width: 1440px) {
  .page-dashboard-reports-row {
    flex-direction: row;
  }
  .page-dashboard-reports-row.single {
    flex-direction: column;
  }
  .page-dashboard-reports-row.double .small-report {
    width: 50%;
  }
  .page-dashboard-reports-row.w-divider {
    position: relative;
  }
  .page-dashboard-reports-row.w-divider::before {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    top: 0%;
    bottom: 0%;
    width: 1px;
    height: auto;
    right: auto;
    background-color: rgba(255, 255, 255, 0.15);
    background-image: linear-gradient(225deg, #d62973 0%, #2973d6 100%);
    opacity: 0.25;
  }
}

.page-dashboard-reports-row.gradient-border {
  position: relative;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.page-dashboard-reports-row.gradient-border:before {
  content: "";
  display: block;
  position: absolute;
  inset: -0.5rem;
  opacity: 0.75;
  background-image: linear-gradient(225deg, #d62973 0%, #2973d6 100%);
  z-index: -1;
  border-radius: 1rem;
}
.page-dashboard-reports-row.gradient-border::after {
  content: "";
  display: block;
  position: absolute;
  inset: 0;
  background-color: #121212;
  z-index: -1;
  border-radius: 1rem;
}
.page-domain {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.page-domain h2 {
  margin-bottom: 0;
}
.page-domain .dns-timeline {
  margin-top: 1rem;
}
.html-page-registrar-log .small-report {
  flex: 1;
}
.page.page-mfa-enroll {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-image: linear-gradient(225deg, black 0%, #08172b 100%);
  margin-top: 3rem;
  margin-bottom: 3rem;
  margin-left: 0;
  margin-right: 0;
  width: 100%;
  max-width: none;
  gap: 1rem;
}
.page.page-mfa-enroll h1 {
  margin-bottom: 0;
}
.page.page-mfa-enroll .ant-input,
.page.page-mfa-enroll .ant-btn {
  max-width: 50%;
}
@media screen and (min-width: 1024px) {
  .page.page-mfa-enroll {
    max-width: 50vw;
    margin-left: auto;
    margin-right: auto;
  }
}

.page-mfa-enroll-qr-container {
  border: 1px solid #333;
  width: 400px;
  height: 400px;
  margin-left: auto;
  margin-right: auto;
}

.page-mfa-enroll-qr {
  display: block;
  width: 400px;
  height: 400px;
}

.page-mfa-enroll-secret {
  text-align: center;
}

.page-mfa-enroll-code {
  text-align: center;
  font-size: 2rem;
}
.page.page-profile {
  width: 100%;
  max-width: 75vw;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (min-width: 1440px) {
  .page.page-profile {
    max-width: 50vw;
  }
}

html.page-profile #header-portal {
  width: 100%;
  max-width: 75vw;
  margin-left: auto;
  margin-right: auto;
}
html.page-profile #header-portal h1 {
  text-align: center;
}
@media screen and (min-width: 1440px) {
  html.page-profile #header-portal {
    max-width: 50vw;
  }
}
.page-system-health .status-ok {
  color: green;
}
.page-system-health .status-error {
  color: red;
}
.page-users-editable-container:hover {
  border: 1px solid #444;
}

.page-users-editable-name {
  display: flex;
  flex-direction: row;
  gap: 2px;
}
.page-watchlists .ant-tag {
  cursor: pointer;
}
.auth-view {
  padding: 2rem;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
  width: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-image: linear-gradient(225deg, black 0%, #08172b 100%);
  margin-top: 3rem;
  margin-bottom: 3rem;
}
.auth-view .icon {
  color: #f5f5f5;
  width: 10rem;
}
.auth-view button:not([type=submit]) {
  background-color: transparent;
}
.auth-view button:not([type=submit]):hover:not(:disabled) {
  background-color: #222;
}
.auth-view > div {
  max-width: 400px;
  width: 100%;
}
@media screen and (min-width: 1024px) {
  .auth-view {
    max-width: 50vw;
    margin-left: auto;
    margin-right: auto;
  }
}
.auth-view.auth-tc-not-ok #auth-sign-up button {
  pointer-events: none !important;
  opacity: 0.3;
}
.table-dns-log .ant-select {
  min-width: 10rem;
}
.domain-expanded-history {
  margin: 0;
}
.domain-expanded-history .ant-table {
  margin: 0 !important;
}
.table-hunt-report-log .ant-select {
  min-width: 10rem;
}
.table-ip-registry-company .ant-select {
  min-width: 10rem;
}
.page-report-companies {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.page-report-companies-selector {
  min-width: 200px;
  width: 100%;
}
.page-reports-countries {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.page-reports-countries-selector {
  width: 100%;
}
.page-ip {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.html-page-ip #header-portal {
  padding: 0;
}
.page-malware {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.page-malware-tags .ant-descriptions-item-label {
  min-width: 8rem;
}
.page-malware-tags .ant-descriptions-item-content > span {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}
.page-malware-tags .ant-tag {
  margin-right: 0;
}